import { Button, Typography } from "antd";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";

const { Title } = Typography;

const Video: React.FC = () => {
  const [showFinishButton, setShowFinishButton] = useState(false);

  return (
    <MainLayout>
      <Title level={2}>Decentralised Education Learning Platform</Title>
      <Title level={3}>{`Communities > Mental Health`}</Title>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: 100,
        }}
      >
        <ReactPlayer
          url="https://wp.musayyab-naveed.com/delp-video.mp4"
          playing
          controls
          onEnded={() => setShowFinishButton(true)}
          style={{
            width: "80%",
            height: "70%",
          }}
        />
        {showFinishButton && (
          <React.Fragment>
            <Link to="/success">
              <Button type="primary" size="large" style={{ margin: 15 }}>
                Generate Certificate
              </Button>
            </Link>
          </React.Fragment>
        )}
      </div>
    </MainLayout>
  );
};

export default Video;
