import { Button, Typography } from "antd";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";

const { Title } = Typography;

const Success: React.FC = () => {
  return (
    <MainLayout>
      <Title
        level={2}
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 100,
        }}
      >
        Congrats on Completing the course!
      </Title>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 100,
        }}
      >
        <Link to="/">
          <Button type="primary" style={{ margin: 15 }} size="large">
            Generate Certificate
          </Button>
        </Link>
      </div>
    </MainLayout>
  );
};

export default Success;
