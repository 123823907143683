import React from "react";
import { Button, Card, Col, MenuProps, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";

const { Meta } = Card;
const { Title } = Typography;

const communities = [
  {
    title: "Mental Health",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268440449200158/mental-health-hands-holding-brain-eJ9Mny-scaled.jpg",
  },
  {
    title: "Business Management",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268489811963945/african-african-american-african-descent-afro-american-analyzing-1447101-pxhere.com.jpg",
  },
  {
    title: "Understanding Different Cultures",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268619562745916/carlos-magno-VuwrQEt_sCE-unsplash.jpg",
  },
  {
    title: "Healthy Eating",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268746994106388/pexels-nathan-cowley-1153370.jpg",
  },
  {
    title: "Networking & Social Skills",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268589737058314/priscilla-du-preez-nF8xhLMmg0c-unsplash.jpg",
  },
  {
    title: "Innovation & Creativity",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268672792674314/light-bulb-4793589_1920.jpg",
  },

  {
    title: "Spanish Language",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268793680887828/man-spectacle-hat-fight-sports-performance-953277-pxhere.com.jpg",
  },
  {
    title: "Parenting",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268813897433128/pexels-pixabay-235554.jpg",
  },
  {
    title: "Sustainability, Renewable Energies",
    description: "",
    img: "https://cdn.discordapp.com/attachments/999873608614678538/1020268527392915527/Boulder-Cowdery-Meadows-Community-Solar-Array.jpg",
  },
];

const Dashboard: React.FC = () => (
  <MainLayout>
    <Title level={2}>Decentralised Education Learning Platform</Title>
    <Title level={3}>
      Provide you with the education you really need in life
    </Title>
    <div className="site-card-wrapper">
      <Title level={3}>Browse Communities</Title>
      <Row gutter={16}>
        {communities.map((value, index) => {
          return (
            <Col span={8} key={index}>
              <Link to="/video">
                <Card
                  hoverable
                  cover={<img alt={value.title} src={value.img} />}
                  style={{ marginTop: 16 }}
                >
                  <Meta title={value.title} description={value.description} />
                </Card>
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  </MainLayout>
);

export default Dashboard;
