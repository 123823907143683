import { DesktopOutlined, FileOutlined } from "@ant-design/icons";
import { Button, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import delpLogo from "../assets/delp-logo.png";
import { useHashconnectService } from "../utils/HashConnectAPIProvider-1.10";
import { accountId, connectToWallet } from "../utils/hashconnect";

const { Header, Content, Footer, Sider } = Layout;

const MainLayout = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    connectToExtension,
    disconnect,
    pairingData,
    availableExtension,
    network,
    pairingString,
  } = useHashconnectService();

  const conCatAccounts = (lastAccs: string, Acc: string) => {
    return lastAccs + " " + Acc;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(pairingString!);
  };

  const handleClick = () => {
    if (availableExtension && !pairingData) connectToExtension();
    else if (pairingData) disconnect();
    else
      alert(
        "Please install hashconnect wallet extension first. from chrome web store."
      );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Link to="/">
          <div className="logo">
            <img src={delpLogo} alt="Logo" />
          </div>
        </Link>
        <Menu theme="dark" mode="inline" style={{ marginTop: 50 }}>
          <Menu.Item key={1}>
            <DesktopOutlined />
            <span>Dashboard</span>
            <Link to="/" />
          </Menu.Item>
          <Menu.Item key={2}>
            <FileOutlined />
            <span>Video</span>
            <Link to="/video" />
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            style={{ margin: 15 }}
            onClick={connectToWallet}
          >
            {accountId ? accountId : "Connect Wallet"}
          </Button>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          DELP ©2022 Created with Love!
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
