import { HashConnect } from "hashconnect";

let accountId = "";
let topic = "";

let hashconnect = new HashConnect();

const connectToWallet = async () => {
  let appData = {
    name: "DELP",
    description: "test",
    icon: "https://cdn-icons-png.flaticon.com/512/6001/6001402.png",
  };

  let initData = await hashconnect.init(appData, "testnet");
  let privateKey = initData.privKey;

  console.log("Private Key - " + privateKey);

  let state = await hashconnect.connect();
  topic = state.topic;
  console.log(topic);

  let pairingString = hashconnect.generatePairingString(
    state,
    "testnet",
    false
  );

  console.log(hashconnect.findLocalWallets());

  hashconnect.connectToLocalWallet(pairingString);

  hashconnect.pairingEvent.once((pairingData) => {
    pairingData.accountIds.forEach((id) => {
      console.log(id);
      accountId = id;
    });
  });
};

// const sendTransaction = async () => {
//     const provider = hashconnect.getProvider('testnet', topic, accountId)

//     const signer = hashconnect.getSigner(provider)

//     let trans = await new TransferTransaction()
//         .addTokenTransfer()
// }

export { connectToWallet, accountId };
